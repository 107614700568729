import React from "react";

type Props = {
  visible: Function;
};
// ??? bug where windows dissapaer from > index
export default function Bio({ visible }: Props) {
  const [isVisible, setIsVisible] = React.useState(true);

  visible(isVisible);

  return (
    <>
      <div className="top mb-2 flex">
        <div
          className="h-3 w-3 bg-red-500 rounded-full"
          onClick={() => setIsVisible(false)}
        />
        <div className="ml-2 h-3 w-3 bg-yellow-400 rounded-full" />
        <div className="ml-2 h-3 w-3 bg-green-500 rounded-full" />
      </div>
      <div className="mt-4 flex flex-col gap-2">
        <span className="text-green-400 pl-2 break-all">
          username:~$ <span className="text-gray-100">dat_insanity</span>
        </span>

        <span className="text-green-400 pl-2 break-all">
          email:~${" "}
          <a href="mailto:dat@insanity.cat" className="text-gray-100">
            dat@insanity.cat
          </a>
        </span>
        <span className="text-green-400 pl-2 break-all">
          github:~${" "}
          <a
            target="_blank"
            href="https://github.com/dat-insanity/"
            className="text-gray-100"
            rel="noreferrer"
          >
            github.com/dat-insanity
          </a>
        </span>
        <span className="text-green-400 pl-2 break-all">
          steam:~${" "}
          <a
            target="_blank"
            href="https://steamcommunity.com/id/dat_insanity/"
            className="text-gray-100"
            rel="noreferrer"
          >
            steamcommunity.com/id/dat_insanity/
          </a>
        </span>
        {/* 
        <button
          onClick={() =>
            props.setItems((items: string[]) => [...items, "window"])
          }
          className="bg-gray-600 text-white ml-2 w-max"
        >
          -&gt; New Window &lt;-
        </button> */}
      </div>
    </>
  );
}
