import { AnimatePresence } from "framer-motion";
import { useRef, useState } from "react";
import Bio from "./components/Bio";
import Window from "./components/Window";
import Projects from "./components/Projects";
import Dock from "./components/Dock";
import Rain from "./components/Rain";
import Visualiser from "./components/Visualiser";

const App = () => {
  const [showBio, setShowBio] = useState(true);
  const [showProjects, setShowProjects] = useState(true);
  const constraintRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        className="h-screen w-screen overflow-hidden flex items-center justify-center z-10"
        ref={constraintRef}
      >
        <AnimatePresence>
          {showBio && (
            <Window constraintRef={constraintRef}>
              <Bio visible={(vis: boolean) => setShowBio(vis)} />
            </Window>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showProjects && (
            <Window constraintRef={constraintRef}>
              <Projects visible={(vis: boolean) => setShowProjects(vis)} />
            </Window>
          )}
        </AnimatePresence>
        <Dock />
      </div>
      <div className="blur-sm absolute top-0 left-0 -z-10">
        <Rain />
      </div>
      <Visualiser />
    </>
  );
};

export default App;
