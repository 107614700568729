import { useEffect } from "react";

declare global {
  interface Window {
    AudioContext: typeof AudioContext;
    webkitAudioContext: typeof AudioContext;
  }
}

export default function App() {
  interface IDrawVisualiser {
    bufferLength: number;
    dataArray: Uint8Array;
    barWidth: number;
  }

  useEffect(() => {
    document.addEventListener("mousemove", parallax);
    const elem = document.getElementById("VisualizerContainer");

    function parallax(e: { clientX: number; clientY: number }) {
      elem!.style.transform =
        "translate3d(" +
        (e.clientX * 1) / 20 +
        "px," +
        (e.clientY * -1) / 20 +
        "px, 0)";
    }
  }, []);

  function playAudio() {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();

    let audioSource: MediaElementAudioSourceNode;
    let analyser: AnalyserNode;
    let audio1 = new Audio();

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    audio1.src = "/song.mp3";
    audio1.play();
    audioSource = audioCtx.createMediaElementSource(audio1);
    analyser = audioCtx.createAnalyser();
    audioSource.connect(analyser);
    analyser.connect(audioCtx.destination);
    analyser.fftSize = 128;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    const barWidth = (canvas.width * 1.25) / bufferLength;

    let x = 0;
    let x1 = canvas.width;

    function animate() {
      x = 0;
      x1 = canvas.width;
      ctx!.clearRect(0, 0, canvas.width, canvas.height);
      analyser.getByteFrequencyData(dataArray);
      drawVisualizer({
        bufferLength,
        dataArray,
        barWidth,
      });
      requestAnimationFrame(animate);
    }

    const drawVisualizer = ({
      bufferLength,
      dataArray,
      barWidth,
    }: IDrawVisualiser) => {
      let barHeight;
      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i];
        const red = (i * barHeight) / 10 + 50;
        const green = i * 4;
        const blue = barHeight / 2.5;

        ctx!.fillStyle = `rgb(${red}, ${green}, ${blue})`;
        ctx!.fillRect(x1, barHeight + 50, barWidth, -barHeight * 2 - 50);
        ctx!.fillRect(
          x,
          canvas.height - barHeight - 50,
          barWidth,
          barHeight * 2 + 50
        );
        x += barWidth;
        x1 -= barWidth;
      }
    };

    animate();
  }

  return (
    <div className="w-screen h-screen absolute top-0 left-0 overflow-clip">
      <button className="z-30 absolute top-10 left-10" onClick={playAudio}>
        play audios
      </button>
      <div
        className="blur-xl w-full h-full top-0 left-0 absolute -z-20"
        id="VisualizerContainer"
      >
        <canvas
          className="absolute top-0 left-0 w-full h-full scale-110"
          id="canvas"
        />
        <audio id="audio" />
      </div>
    </div>
  );
}
