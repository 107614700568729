import { useEffect, CSSProperties } from "react";
import "./Rain.css";

export default function App() {
  interface CustomCss extends CSSProperties {
    "--left": number;
    "--top": number;
    "--opacity": number;
    "--duration": number;
    "--delay": number;
    "--scale": number;
  }

  useEffect(() => {
    document.addEventListener("mousemove", parallax);
    const elem = document.getElementById("rainbg");

    function parallax(e: { clientX: number; clientY: number }) {
      elem!.style.transform =
        "translate3d(" +
        (e.clientX * -1) / 15 +
        "px," +
        (e.clientY * -1) / 30 +
        "px, 0)";
    }
  }, []);

  return (
    <div className="rain" id="rainbg">
      {[...Array(100)].map((x, i) => (
        <svg
          key={i}
          className="rain_drop"
          viewBox="0 0 5 50"
          style={
            {
              "--left": Math.floor(Math.random() * 100),
              "--top": Math.floor(Math.random() * 100),
              "--opacity": Math.random(),
              "--duration": Math.random() + 0.5,
              "--delay": Math.random() * 2 - 1,
              "--scale": Math.random(),
            } as CustomCss
          }
        >
          <path d="M 2.5,0 C 2.6949458,3.5392017 3.344765,20.524571 4.4494577,30.9559 5.7551357,42.666753 4.5915685,50 2.5,50 0.40843152,50 -0.75513565,42.666753 0.55054234,30.9559 1.655235,20.524571 2.3050542,3.5392017 2.5,0 Z" />
        </svg>
      ))}
    </div>
  );
}
