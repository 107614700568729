import { motion } from "framer-motion";

type Props = {
  constraintRef: React.RefObject<HTMLDivElement>;
  children: JSX.Element;
};

export default function Window({ children, constraintRef }: Props) {
  return (
    <motion.div
      drag
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.3 }}
      whileDrag={{ scale: 0.95 }}
      dragConstraints={constraintRef}
      exit={{ opacity: 0, scale: 0.5 }}
      className="fixed inverse-toggle px-5 shadow-xl text-gray-100 text-sm font-mono subpixel-antialiased bg-gray-800 pb-6 pt-4 rounded-lg leading-normal overflow-hidden w-5/6 md:w-3/4 lg:w-1/2"
    >
      {children}
    </motion.div>
  );
}
