import React from "react";

type Props = {
  visible: Function;
};

export default function Projects({ visible }: Props) {
  const [isVisible, setIsVisible] = React.useState(true);
  visible(isVisible);

  return (
    <>
      <div className="top mb-2 flex">
        <div
          className="h-3 w-3 bg-red-500 rounded-full"
          onClick={() => setIsVisible(false)}
        />
        <div className="ml-2 h-3 w-3 bg-yellow-400 rounded-full" />
        <div className="ml-2 h-3 w-3 bg-green-500 rounded-full" />
      </div>
      <div className="mt-4 flex flex-col">
        <p className="text-gray-100 pl-2 break-all">
          ╭─<span className="text-pink-500">you</span> at
          <span className="text-orange-500"> insanity.cat</span> in
          <span className="text-green-500"> ⌁/projects</span>
        </p>
        <p className="text-gray-100 pl-2 break-all">
          ╰─<span className="text-blue-500">λ </span>tree
        </p>

        <p className="text-gray-100 pl-2 break-all">
          .<br />
          ├── <span className="text-blue-400">files.insanity.cat</span>
          <br />
          │  ├── db_mongo.js <br />
          │  ├── frontend_next.js <br />
          │  ├── server_express.js <br />
          │  └── under_construction.js <br />
          ├── <span className="text-blue-400">insanity.cat</span> <br />
          │  ├── framer.js <br />
          │  ├── react.tsx <br />
          │  └── tailwind.css <br />
          ├── <span className="text-blue-400">r34.insanity.cat</span> <br />
          │  ├── backend_next.js <br />
          │  ├── frontend_next.js <br />
          │  ├── nsfw.js <br />
          │  └── nsfw.warn <br />
          └── <span className="text-blue-400">vortex.sh</span> <br />   └──
          frontend_dev.svelte
        </p>
      </div>
    </>
  );
}
