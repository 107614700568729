import Clock from "./Clock";

const Dock = () => {
  return (
    <div className="flex items-center bg-black/60 backdrop-blur h-10 bottom-0 w-full fixed text-gray-300 font-mono">
      <div className="ml-auto pr-4">
        <Clock />
      </div>
    </div>
  );
};

export default Dock;
